import axios from "axios"

const BALLPARK_BACKEND_BASE_URL = process.env.REACT_APP_BALLPARK_API_URL;

export const getOrderHistoryBySchool = (user, data, startDate, endDate, view) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken
    };
    return axios.post(
        BALLPARK_BACKEND_BASE_URL + "getOrderHistory",
        { data, startDate, endDate, view },
        { headers }
    );
};