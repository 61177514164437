import * as React from "react";
import * as Theme from "../contexts/ThemeContext";
import { CssBaseline, ThemeProvider, Typography } from "@mui/material";
import { SnackbarProvider } from "notistack";
import "../App.css";
import { signOutWithGoogle } from "../API/firebase.ts";
import { useNavigate } from "react-router-dom";

const UserContext = React.createContext();
const RoleContext = React.createContext({ role: "", setRole: () => { } });
const PermissionContext = React.createContext({
  permission: {},
  availableRoles: [],
  actions: {},
  views: {},
});
const SchoolContext = React.createContext({ schools: [], setSchool: () => { } });
const GenderedSportListContext = React.createContext([]);
const UserSchoolsContext = React.createContext([]);
const GlobalThemeContext = React.createContext({
  theme: {},
  setTheme: () => { },
  isNavOpen: true,
  setIsNavOpen: () => { },
});

export const useUserContext = () => React.useContext(UserContext);
export const useRoleContext = () => React.useContext(RoleContext);
export const usePermissionContext = () => React.useContext(PermissionContext);
export const useSchoolContext = () => React.useContext(SchoolContext);
export const useGenderedSportList = () =>
  React.useContext(GenderedSportListContext);
export const useUserSchools = () => React.useContext(UserSchoolsContext);
export const useGlobalThemeContext = () => React.useContext(GlobalThemeContext);

const ContextProvider = (props) => {
  const [user, setUser] = React.useState(props.user);
  const [permission, setPermission] = React.useState(props.permission);

  React.useEffect(() => {
    props.setRole(props.role);
    setUser(props.user);
    setPermission(props.permission);
  }, [props.role, props.user, props.permission]);

  return (
    <SnackbarProvider
      classes={{
        containerRoot: "customSnackbar",
      }}
    >
      <GlobalThemeContext.Provider
        value={{
          theme: props.theme,
          setTheme: props.setTheme,
          isNavOpen: props.isNavOpen,
          setIsNavOpen: props.setIsNavOpen,
        }}
      >
        <ThemeProvider
          theme={
            props.theme === "theme-light" ? Theme.lightTheme : Theme.darkTheme
          }
        >
          <CssBaseline />
          <UserContext.Provider value={user ? { ...user } : user}>
            {user?.uid && props.role && props.role !== "New Account" && permission ? (
              <RoleContext.Provider value={{ role: props.role, setRole: props.setRole }}>
                <PermissionContext.Provider
                  value={{
                    permission,
                    availableRoles: props.availableRoles,
                    actions: props.actions,
                    views: props.views,
                  }}
                >
                  <SchoolContext.Provider
                    value={{
                      schools: props.schools,
                      setSchools: props.setSchools,
                    }}
                  >
                    <UserSchoolsContext.Provider value={props.userSchools}>
                      <GenderedSportListContext.Provider
                        value={props.genderedSportList}
                      >
                        {props.children}
                      </GenderedSportListContext.Provider>
                    </UserSchoolsContext.Provider>
                  </SchoolContext.Provider>
                </PermissionContext.Provider>
              </RoleContext.Provider>
            ) : (
              <div
                style={{
                  width: "100%",
                  padding: "50px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  style={{
                    width: "25%",
                    animation: `spin 10s linear infinite`,
                  }}
                  alt=""
                  src="/rotatelogo.png"
                />
                <Typography>Please wait, verifying your identity!</Typography>
                {/* <CountdownRedirect /> */}
              </div>
            )}
          </UserContext.Provider>
        </ThemeProvider>
      </GlobalThemeContext.Provider>
    </SnackbarProvider>
  );
};

const CountdownRedirect = () => {
  const [count, setCount] = React.useState(15);
  const navigate = useNavigate();

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev) => {
        if (prev <= 1) {
          signOutWithGoogle().then(() => {
            navigate("/login");
          });
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [navigate]);

  return <div>Redirecting in {count} seconds...</div>;
};

export default ContextProvider;
