import axios from "axios"

const BALLPARK_BACKEND_BASE_URL = process.env.REACT_APP_BALLPARK_API_URL;
const BASE_HATTRICK_URL = process.env.REACT_APP_HATTRICK_URL;


export const getTransferById = (user, idTransferPortal) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    return axios.post(
        BASE_HATTRICK_URL + "getTransferById",
        idTransferPortal,
        { headers }
    );
};

// GET Transfer record for individual athletes - BallParkAPI
export const getTransfersByMerchId = (user, idAthleteMerchInfo) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    console.log('in GET TRANSFER MERCH ID')
    return axios.post(
        BASE_HATTRICK_URL + "getTransfersByMerchId",
        // BALLPARK_BACKEND_BASE_URL + "getTransfersByMerchId",
        idAthleteMerchInfo,
        { headers }
    );
};

export const getTransfers = (user) => {
    let headers = {
        "Content-Type": "application/json",
        // "Content-Encoding": "gzip",
        // "Accept-Encoding":"gzip",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    return axios.get(
        BASE_HATTRICK_URL + "getTransfers",
        { headers }
    );
};

export const updateTransferCheckBackDate = (user, transferId, date) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    return axios.post(
        BASE_HATTRICK_URL + "transfer/checkBack",
        {idTransferPortal:transferId, checkBack:date},
        { headers }
    );
};

export const updateTransferNotes = (user, transferId, notes) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    return axios.post(
        BASE_HATTRICK_URL + "transfer/notes",
        {idTransferPortal:transferId, notes:notes},
        { headers }
    );
};

export const updateTransferReason = (user, transferId, reason) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    return axios.post(
        BASE_HATTRICK_URL + "transfer/reason",
        {idTransferPortal:transferId, reason: reason},
        { headers }
    );
};

export const createTransfer = (
    user,
    idAthleteMerchInfo,
    new_university,
    reason
) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    return axios.post(
        BALLPARK_BACKEND_BASE_URL + "transferPortal",
        {idAthleteMerchInfo, new_university, reason},
        { headers }
    );
};

export const undoTransfer = (
    user,
    idAthleteMerchInfo
) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    return axios.post(
        BALLPARK_BACKEND_BASE_URL + "transferPortalUndo",
        {idAthleteMerchInfo},
        { headers }
    );
};

export const takeLive = (
    user,
    idAthleteMerchInfo
) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    return axios.post(
        BALLPARK_BACKEND_BASE_URL + "takeLiveTransfers",
        {idAthleteMerchInfo},
        { headers }
    );
};

export const retire = (
    user, 
    merchRecord
) => {
    let headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    return axios.post(
        BALLPARK_BACKEND_BASE_URL + "retireTransfers",
        {merchRecord},
        { headers }
    );
}