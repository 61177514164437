import React, { useState, useEffect } from 'react';
import {
    TextField,
    CircularProgress,
    Grid,
    Box,
    Checkbox,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { fetchAllProductMetadata } from "../../../../API/audible";

export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};

const ProductMetadataTable = ({ user, notify, selectedProducts, setSelectedProducts, refresh, setArchiveSKUs }) => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const debouncedSearchQuery = useDebounce(searchQuery.trim(), 500);

    useEffect(() => {
        if (debouncedSearchQuery.length > 0) {
            handleFetchALLProductsMetadata()
            // setArchiveSearchQ(debouncedSearchQuery)
        }
        else {
            setLoading(false)
        }
    }, [user.stsTokenManager.accessToken, debouncedSearchQuery]);

    useEffect(() => {
        setSearchQuery("");
        setSelectedProducts([]);
        if (debouncedSearchQuery.length > 0) {
            handleFetchALLProductsMetadata()
            // setArchiveSearchQ(debouncedSearchQuery)
        } else {
            setLoading(false)
        }
    }, [refresh]);

    const handleFetchALLProductsMetadata = () => {
        setLoading(true);
        fetchAllProductMetadata(user, debouncedSearchQuery)
            .then(response => {
                setProducts(response.data.obj.products || []);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching product metadata:', error);
                notify("Oops, Something is wrong when fetching Products Metadata");
                setLoading(false);
            });
    };

    const handleRowClick = (rowData, meta) => {
        const id = rowData[0];
        const sku_name = rowData[1];
        setSelectedProducts(prevSelected => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter(item => item !== id);
            } else {
                return [...prevSelected, id];
            }
        });
        // Update the archive search queue
        setArchiveSKUs(prevArchiveSKU => {
            if (prevArchiveSKU.includes(sku_name)) {
                return prevArchiveSKU.filter(item => item !== sku_name);
            } else {
                return [...prevArchiveSKU, sku_name];
            }
        });
    };

    const handleSelectAllToggle = (event) => {
        const isChecked = event.target.checked;
        const pageProducts = products.map(product => product.product_metadata_id);

        if (isChecked) {
            setSelectedProducts(pageProducts);
        } else {
            setSelectedProducts([]);
        }
    };

    const baseColumns = [
        { name: 'product_metadata_id', options: { display: false, filter: false } },
        { name: 'sku_name', label: 'SKU Name', options: { filter: true, sort: false } },
        { name: 'university', label: 'University', options: { filter: true, sort: false } },
        { name: 'sport', label: 'Sport', options: { filter: true, sort: false } },
        { name: 'gender', label: 'Gender', options: { filter: true, sort: false } },
        { name: 'jersey_number', label: 'Jersey Number', options: { filter: true, sort: false } },
    ];

    const selectedColumns = [
        ...baseColumns,
        {
            name: 'deselectALL',
            label: (
                <Checkbox
                    color="primary"
                    indeterminate={selectedProducts.length > 0 && selectedProducts.length < products.length}
                    checked={selectedProducts.length === products.length}
                    onChange={handleSelectAllToggle}
                />
            ),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta) => {
                    const id = tableMeta.rowData[0];
                    const isSelected = selectedProducts.includes(id);
                    return (
                        <Checkbox
                            color="primary"
                            checked={isSelected}
                            onChange={() => handleRowClick(tableMeta.rowData)}
                        />
                    );
                }
            }
        }
    ];

    const allColumns = [
        ...baseColumns,
        {
            name: 'selectALL',
            label: (
                <Checkbox
                    color="primary"
                    indeterminate={selectedProducts.length > 0 && selectedProducts.length < products.length}
                    checked={selectedProducts.length === products.length}
                    onChange={handleSelectAllToggle}
                />
            ),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta) => {
                    const id = tableMeta.rowData[0];
                    const isSelected = selectedProducts.includes(id);
                    return (
                        <Checkbox
                            color="primary"
                            checked={isSelected}
                            onChange={() => handleRowClick(tableMeta.rowData)}
                        />
                    );
                }
            }
        }
    ];

    const baseOptions = {
        filterType: 'checkbox',
        responsive: 'standard',
        rowsPerPageOptions: [10, 25, 50, { label: 'All', value: -1 }],
        searchText: searchQuery,
        onSearchChange: (searchText) => setSearchQuery(searchText),
        selectableRows: 'none', // Disable MUI Data Table's internal row selection
        search: false,
        print: false,
        download: false,
        viewColumns: false,
        sorting: true,
        onChangePage: (page) => setCurrentPage(page),
        onChangeRowsPerPage: (rowsPerPage) => {
            console.log(rowsPerPage)
            setRowsPerPage(rowsPerPage);
            setCurrentPage(0);
        },
        rowsPerPage: rowsPerPage === -1 ? products.length : rowsPerPage, // Handle "All" case
        page: currentPage,
    };

    const selectedOptions = {
        ...baseOptions,
        count: selectedProducts.length,
        pagination: false, // Disable pagination for the selectedProducts table
    };

    const allOptions = {
        ...baseOptions,
        textLabels: {
            body: {
                noMatch: loading ? (
                    <CircularProgress />
                ) : (
                    "Please check your filter: No Metadata Has been Found"
                ),
            },
        },
    };

    const selectedProductDetails = products.filter(product => selectedProducts.includes(product.product_metadata_id));

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '20px' }}>
                <TextField
                    variant="outlined"
                    label="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ border: 1, borderRadius: 2, boxShadow: 3, marginBottom: '20px', overflow: 'auto', maxHeight: 300 }}>
                    <MUIDataTable
                        title={`Selected Product Metadata (${selectedProductDetails.length})`}
                        data={selectedProductDetails}
                        columns={selectedColumns}
                        options={selectedOptions}
                    />
                </Box>
                <Box sx={{ border: 1, borderRadius: 2, boxShadow: 3, overflow: 'auto', maxHeight: 950 }}>
                    {/*Think of a way to set the pagniation section fixed to the table so that its always visible*/}
                    <MUIDataTable
                        title="Product Metadata"
                        data={products}
                        columns={allColumns}
                        options={allOptions}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default ProductMetadataTable;