import axios from 'axios'

const BALLPARK_BACKEND_BASE_URL = process.env.REACT_APP_BALLPARK_API_URL
const BASE_HATTRICK_URL = process.env.REACT_APP_HATTRICK_URL

export const getRosterAndMerchForSchool = (
  school,
  user,
  setRoster,
  callback
) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  return axios.get(
    BALLPARK_BACKEND_BASE_URL +
      'allProductsCollectionsAndMerchRosters?uni=' +
      btoa(school),
    { headers }
  )
}
export const searchRosterForName = (searchTerm, user) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  return axios.get(
    // BALLPARK_BACKEND_BASE_URL + "searchRosterByName?search=" + searchTerm,
    BASE_HATTRICK_URL + 'searchRosterByName?search=' + searchTerm,
    { headers }
  )
}

export const updateMerchRoster = (merchRoster, user, canDeleteProducts) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  return axios.post(
    BASE_HATTRICK_URL + 'products/',
    { ...merchRoster, canDeleteProducts },
    { headers }
  )
}

export const updateUser = (userUpdates, user) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  return axios.post(
    BASE_HATTRICK_URL + 'user/',
    userUpdates,
    { headers }
  )
}

export const updateMerchRosterTransfer = (user, merchRoster) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  return axios.post(
    BALLPARK_BACKEND_BASE_URL + 'updateMerchRoster',
    merchRoster,
    { headers }
  )
}
export const getRostersWithoutProductMockups = (user, school, sport) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  return axios.get(
    BALLPARK_BACKEND_BASE_URL +
      'getMerchRostersMissingProductMockups?uni=' +
      btoa(school) +
      '&sport=' +
      btoa(sport),
    {
      headers
    }
  )
}
export const getRostersWithoutArtFiles = (
  user,
  selectedSchool,
  selectedSport
) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  return axios.get(
    BALLPARK_BACKEND_BASE_URL +
      'getMerchRostersMissingArtFiles?uni=' +
      btoa(selectedSchool) +
      '&sport=' +
      btoa(selectedSport),
    {
      headers
    }
  )
}
export const getRosterByAuthEmployeeSchool = (user) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  return axios.get(
    BALLPARK_BACKEND_BASE_URL + 'getRosterByAuthEmployeeSchool',
    {
      headers
    }
  )
}
export const getCountRosterWhereNotPostedByUniversity = (user) => {
  console.log('user access token', user.stsTokenManager.accessToken)
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  return axios.get(
    BALLPARK_BACKEND_BASE_URL + 'getCountRosterWhereNotPostedByUniversity',
    {
      headers
    }
  )
}
export const getUnMatchedRosters = (school, user) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  return axios.get(
    BALLPARK_BACKEND_BASE_URL +
      'getRosterWhereUnmatchedByUniversity?uni=' +
      btoa(school),
    {
      headers
    }
  )
}
export const getMerchRosterBySchool = (school, user) => {
  // console.log(school, btoa(school))
  // let headers = {
  //   "Content-Type": "application/json",
  //   Authorization: "Bearer " + user.stsTokenManager.accessToken,
  // };
  const headers = {
    'Content-Type': 'application/json',
    'Content-Encoding': 'gzip',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  return axios.get(
    // BALLPARK_BACKEND_BASE_URL + "getMerchRosterBySchool?uni=" + btoa(school),
    BASE_HATTRICK_URL + 'getMerchRosterBySchool?uni=' + btoa(school),
    {
      headers
    }
  )
}
export const matchRosterRecords = (merchRosterUpdateObject, user) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  return axios.post(
    BALLPARK_BACKEND_BASE_URL + 'matchRoster',
    merchRosterUpdateObject,
    {
      headers
    }
  )
}
export const getUnmatchedCountForUniversities = (user) => {
  console.log('user access token', user.stsTokenManager.accessToken)
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  return axios.get(BALLPARK_BACKEND_BASE_URL + 'unmatchedRosterCounts', {
    headers
  })
}
export const getAllSportsBySchool = (school, user) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  return axios.get(
    // BALLPARK_BACKEND_BASE_URL + "getAllSportsBySchool?uni=" + btoa(school),
    BASE_HATTRICK_URL + 'getAllSportsBySchool?uni=' + btoa(school),
    {
      headers
    }
  )
}
export const getAnalyticsOptions = (data, user) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  console.log('Analytics options', data)
  return axios.post(
    BALLPARK_BACKEND_BASE_URL + 'getAnalyticsOptions',
    data,
    {
      headers
    }
  )
}

// Zeroout API call to GrandSlam
export const getZeroOutGS = (user) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }

  return axios.get(
    BASE_HATTRICK_URL + 'zeroOut',
    {
      headers
    }
  )
}

export const uploadRosterCSV = (files, meta, user, callback) => {
  const formData = new FormData()
  // console.log(files);
  files.forEach((file) => {
    formData.append('file', file)
  })
  formData.append('meta', JSON.stringify(meta))

  return axios.post(BASE_HATTRICK_URL + 'roster/create', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + user.stsTokenManager.accessToken
    }
  })
}

export const getSocialMediaGraphics = (user, school, sport, player) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + user.stsTokenManager.accessToken
    }
  }
  return axios.get(
    BALLPARK_BACKEND_BASE_URL +
      'socialMediaGraphics?player=' +
      player +
      '&sport=' +
      btoa(sport) +
      '&college=' +
      btoa(school),
    config
  )
}

export const getRosterAndCollection = (user) => {
  const headers = {
    'Content-Type': 'application/json',
    // "Content-Encoding": "gzip",
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  return axios.get(
    BALLPARK_BACKEND_BASE_URL + 'getMerchRosterWithCollections',
    { headers }
  )
}

export const createSocialInternRecord = (
  school,
  name,
  email,
  password,
  user
) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }
  return axios.post(
    BASE_HATTRICK_URL + 'socialIntern/appAccount/create',
    { school, name, email, password },
    { headers }
  )
}

export const getAllReferralByAthleteId = (user, athleteId) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }

  return axios.get(
    BASE_HATTRICK_URL +
      'roster/referral?' +
      (athleteId ? '&athleteId=' + athleteId : ''),
    { headers }
  )
}

export const reSendReward = (id, user) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }

  return axios.post(
    BASE_HATTRICK_URL + 'roster/resendReward',
    {
      rewardId: id
    },
    { headers }
  )
}

export const createReward = (rewardedAthleteId, associatedAthleteRosterId, bonusType, amount, user) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }

  return axios.post(
    BASE_HATTRICK_URL + 'roster/createReward',
    {
      rewardedAthleteId,
      associatedAthleteRosterId,
      bonusType,
      amount

    },
    { headers }
  )
}

export const getPlayer = (userId, athleteId, rosterId, user) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + user.stsTokenManager.accessToken
  }

  // Build query parameters dynamically
  const params = {}
  if (userId !== undefined) params.userId = userId
  if (athleteId !== undefined) params.athleteId = athleteId
  if (rosterId !== undefined) params.rosterId = rosterId

  // Make the GET request with query parameters
  return axios.get(BASE_HATTRICK_URL + 'roster/player', {
    headers,
    params
  })
}
