import axios from "axios";

const BALLPARK_BACKEND_BASE_URL = process.env.REACT_APP_BALLPARK_API_URL;

export const getSocialAccounts = (user) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.stsTokenManager.accessToken,
    },
  };

  return axios.get(BALLPARK_BACKEND_BASE_URL + "getSocialAccounts", config);
};

export const updateSocialAccounts = (updateMeta, user) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.stsTokenManager.accessToken,
    },
  };

  return axios.post(
    BALLPARK_BACKEND_BASE_URL + "updateSocialAccount",
    updateMeta,
    config
  );
};

export const createSocialAccount = (schoolName, user) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.stsTokenManager.accessToken,
    },
  };

  return axios.post(
    BALLPARK_BACKEND_BASE_URL + "createSocialAccount",
    { schoolName },
    config
  );
};


export const getPlayerOutreach = (schoolName, user) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.stsTokenManager.accessToken,
    },
  };

  return axios.get(
    BALLPARK_BACKEND_BASE_URL + "getPlayerOutreach?uni=" + btoa(schoolName), config
  );
}

export const putPlayerOutreach = (updatedict, user) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.stsTokenManager.accessToken,
    },
  };

  return axios.put(BALLPARK_BACKEND_BASE_URL + "putPlayerOutreach", updatedict, config);
}

export const getSchoolOutreach = (user) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.stsTokenManager.accessToken,
    },
  };

  return axios.get(
    BALLPARK_BACKEND_BASE_URL + "getUniversityOutreach", config
  );
}

export const putUniversityOutreach = (updatedict, user) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.stsTokenManager.accessToken,
    },
  };

  return axios.put(BALLPARK_BACKEND_BASE_URL + "putUniversityOutreach", updatedict, config);
}