import * as React from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import ErrorPage from "./components/common/ErrorPage.jsx";
import AppLayout from "./AppLayout.jsx";

const App = () => {
  window.rowsPerPage = 100;
  const [user, setUser] = React.useState(null);

  const componentContext = require.context(
    "./components/modules",
    true,
    /index\.jsx$/
  );
  const componentModules = componentContext.keys().map(componentContext);

  const routes = componentModules.map((mod) => {
    return {
      path: mod.path,
      element: <mod.Component />,
      errorElement: <ErrorPage />,
    };
  });

  const AppComponent = <AppLayout setUser={setUser} user={user ? JSON.parse(JSON.stringify(user)) : null} />;

  const router = createBrowserRouter([
    { path: "/", element: <Navigate to="/login" /> },
    {
      path: "/login",
      element: <Login user={user} />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/ballpark",
      element: AppComponent,
      children: routes,
      errorElement: <ErrorPage />,
    },
  ]);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
        <meta charset="utf-8" />
        <link rel="icon" href="../public/influxer.svg" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Where Influxer runs the show" />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
        <title>Influxer Admin</title>
      </Helmet>
      <RouterProvider router={router} />
    </>
  );
};

export default App;
