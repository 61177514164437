import React, { useState, useEffect } from 'react';
import {
    TextField,
    CircularProgress,
    Grid,
    Box,
    Checkbox,
    Chip,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { fetchCollections } from "../../../../API/audible";
import { useUserContext } from "../../../../contexts/ProviderProvider";
import useNotification from "../../../../hooks/useNotification";

export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};

const CollectionSelection = ({ selectedCollections, setSelectedCollections, refresh }) => {
    const [collections, setCollections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchTerms, setSearchTerms] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const user = useUserContext();
    const notify = useNotification();

    const finalSearchQuery = searchTerms.join(",");
    const debouncedSearchQuery = useDebounce(finalSearchQuery, 500);

    useEffect(() => {
        if (debouncedSearchQuery.length > 0) {
            // console.log(selectedCollections);
            handleFetchCollections();
        } else {
            setLoading(false);
        }
    }, [user.stsTokenManager.accessToken, debouncedSearchQuery, searchTerms]);

    useEffect(() => {
        setSearchQuery("");
        setSelectedCollections([]);
        if (debouncedSearchQuery.length > 0) {
            handleFetchCollections();
        } else {
            setLoading(false);
        }
    }, [refresh]);

    const handleFetchCollections = () => {
        setLoading(true);
        fetchCollections(user, debouncedSearchQuery)
            .then(response => {
                setCollections(response.data.obj["Collections to be Audited"] || []);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching collections:', error);
                notify("Oops, Something is wrong when fetching collections");
                setLoading(false);
            });
    };

    const handleRowClick = (rowData) => {
        const collectionId = rowData[0];
        const selectedCollection = collections.find(collection => collection.CollectionId === collectionId);
        setSelectedCollections(prevSelected => {
            if (prevSelected.find(item => item.CollectionId === collectionId)) {
                return prevSelected.filter(item => item.CollectionId !== collectionId);
            } else {
                return [...prevSelected, selectedCollection];
            }
        });
    };

    const handleSelectAllToggle = (event) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            setSelectedCollections(collections);
        } else {
            setSelectedCollections([]);
        }
    };

    const handleSearchKeyPress = (event) => {
        if ((event.key === 'Enter') && searchQuery.trim()) {
            setSearchTerms([...searchTerms, searchQuery.trim()]);
            setSearchQuery("");
        }
    };

    const handleDeleteSearchTerm = (termToDelete) => () => {
        setSearchTerms((terms) => terms.filter((term) => term !== termToDelete));
    };

    const columns = [
        { name: 'CollectionId', options: { filter: false } },
        { name: 'FullName', label: 'Athlete Name', options: { filter: true, sort: false } },
        { name: 'University', label: 'University', options: { filter: true, sort: false } },
        { name: 'Sport', label: 'Sport', options: { filter: true, sort: false } },
        {
            name: 'selectALL',
            label: (
                <Checkbox
                    color="primary"
                    indeterminate={selectedCollections.length > 0 && selectedCollections.length < collections.length}
                    checked={selectedCollections.length === collections.length}
                    onChange={handleSelectAllToggle}
                />
            ),
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta) => {
                    const id = tableMeta.rowData[0];
                    const isSelected = !!selectedCollections.find(collection => collection.CollectionId === id);
                    return (
                        <Checkbox
                            color="primary"
                            checked={isSelected}
                            onChange={() => handleRowClick(tableMeta.rowData)}
                        />
                    );
                }
            }
        }
    ];

    const options = {
        filterType: 'checkbox',
        responsive: 'standard',
        rowsPerPageOptions: [10, 25, 50, { label: 'All', value: -1 }],
        searchText: searchQuery,
        onSearchChange: (searchText) => setSearchQuery(searchText),
        selectableRows: 'none', // Disable MUI Data Table's internal row selection
        search: false,
        print: false,
        download: false,
        viewColumns: false,
        sorting: true,
        onChangePage: (page) => setCurrentPage(page),
        onChangeRowsPerPage: (rowsPerPage) => {
            setRowsPerPage(rowsPerPage);
            setCurrentPage(0);
        },
        rowsPerPage: rowsPerPage === -1 ? collections.length : rowsPerPage, // Handle "All" case
        page: currentPage,
        textLabels: {
            body: {
                noMatch: loading ? (
                    <CircularProgress />
                ) : (
                    "Please check your filter: No Collections Found"
                ),
            },
        },
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} style={{ textAlign: 'center', marginBottom: '20px' }}>
                <TextField
                    variant="outlined"
                    label="Search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyPress={handleSearchKeyPress}
                    helperText="Use ALL for retrieving Al athletes collections"
                    fullWidth
                />
                <Box mt={2}>
                    {searchTerms.map((term, index) => (
                        <Chip
                            key={index}
                            label={term}
                            onDelete={handleDeleteSearchTerm(term)}
                            color="primary"
                            style={{ marginRight: 5, marginBottom: 5 }}
                        />
                    ))}
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ border: 1, borderRadius: 2, boxShadow: 3, marginBottom: '20px', overflow: 'auto', maxHeight: 500 }}>
                    <MUIDataTable
                        title={`Selected Collections (${selectedCollections.length})`}
                        data={selectedCollections}
                        columns={columns}
                        options={options}
                    />
                </Box>
                <Box sx={{ border: 1, borderRadius: 2, boxShadow: 3, overflow: 'auto', maxHeight: 950 }}>
                    <MUIDataTable
                        title="Collections"
                        data={collections}
                        columns={columns}
                        options={options}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default CollectionSelection;