import axios from "axios";

const BASE_HATTRICK_URL = process.env.REACT_APP_HATTRICK_URL;

// make this more generic

export const fetchAllProductMetadata = (user, searchQuery = "") => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };

    return axios.get(`${BASE_HATTRICK_URL}bulkOp/archiveAllProductMetadata?search=${searchQuery}`, { headers });
};


export const handleCancelPendingOp = (TaskID, user) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };

    return axios.post(`${BASE_HATTRICK_URL}bulkOp/pendingOpCancellation`, { TaskID }, { headers });
};

export const fetchFilterOptions = (user) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    return axios.get(`${BASE_HATTRICK_URL}bulkOp/filterOptions`, { headers });
};


export const startBulkArchiveSummary = (user, selectedProducts) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    const requestBody = {
        metadataIds: selectedProducts
    };

    return axios.post(`${BASE_HATTRICK_URL}bulkOp/archiveSummary`, requestBody, { headers });
};

export const startProductAuditOperation = (user, requestBody) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };

    return axios.post(`${BASE_HATTRICK_URL}bulkOp/productAuditQuery`, requestBody, { headers });
};

export const fetchAllBulkOperations = (user) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    return axios.get(`${BASE_HATTRICK_URL}bulkOp/allBulkOperations`, { headers });
};

// a bit complicated to return a promise for this one
export const fetchFilteredProducts = async (user, requestBody) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    try {
        const response = await axios.post(`${BASE_HATTRICK_URL}bulkOp/valueSummary`, requestBody, {headers});
        const validProducts = response.data.obj?.["Valid Shopify Product"];
        return Array.isArray(validProducts) ? validProducts : [];
    } catch (error) {
        console.error("Error fetching FilteredProducts:", error);
        throw error; // so that we can catch that outside
    }
};

export const ValueUpdate = (user, requestBody) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    return axios.post(`${BASE_HATTRICK_URL}bulkOp/valueUpdate`, requestBody, { headers });
};

export const DescriptionUpdate = (user, requestBody) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    return axios.post(`${BASE_HATTRICK_URL}bulkOp/descriptionUpdate`, requestBody, { headers });
};

export const ArchiveUpdate = (user, requestBody) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    return axios.post(`${BASE_HATTRICK_URL}bulkOp/archiveUpdate`, requestBody, { headers });
};

export const VariantsCreationUpdate = (user, requestBody) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };
    return axios.post(`${BASE_HATTRICK_URL}bulkOp/variantsCreation`, requestBody, { headers });
};

export const getTypeOptionSelections = (user, sku = "") => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };

    return axios.get(`${BASE_HATTRICK_URL}bulkOp/typeOptionSelection`, {
        headers,
        params: { sku },
    });
};

export const fetchCollections = (user, searchQ) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };

    const requestBody = {
        searchQ
    };

    return axios.post(`${BASE_HATTRICK_URL}bulkOp/productAuditSummary`, requestBody, { headers });
};

export const generateAuditCSV = async (user, taskID) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };

    const response = await axios.get(`${BASE_HATTRICK_URL}bulkOp/generateAuditCSV/${taskID}`, {
        headers,
        responseType: 'blob'
    });

    // Create a URL for the file and trigger a download
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `audit_report_task_${taskID}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const fetchVariantsCreationSummary = (user, type) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };

    const requestBody = {
        searchQ: type, // Single type as searchQ
    };

    return axios.post(`${BASE_HATTRICK_URL}bulkOp/variantsCreationSummary`, requestBody, { headers });
};

export const fetchSizesForType = (user, type) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };

    const requestBody = {
        searchQ: type,
    };

    return axios.post(`${BASE_HATTRICK_URL}bulkOp/typeValues`, requestBody, { headers });
};

export const fetchLocations = (user) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };

    return axios.get(`${BASE_HATTRICK_URL}bulkOp/locations`, { headers });
};

export const fetchBulkOperationData = (user, operationId) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };

    return axios.get(`${BASE_HATTRICK_URL}bulkOp/checkBulkOperation/${operationId}`, {
        headers
    });
};

export const fetchCurrentBulkOperationData = (user, operationId) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.stsTokenManager.accessToken,
    };

    return axios.get(`${BASE_HATTRICK_URL}bulkOp/currentBulkOperation`, {
        headers
    });
};

