import axios from "axios";

const BASE_HATTRICK_URL = process.env.REACT_APP_HATTRICK_URL;

export async function getJobs(user, axiosOptions, page, size, order, search, filter) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.stsTokenManager.accessToken,
  };

  return axios.get(BASE_HATTRICK_URL + `marketplace/job/all?page=${page}&size=${size}&order=${btoa(JSON.stringify(order))}&search=${btoa(search ? search : "")}&filter=${btoa(JSON.stringify(filter))}`,
    { ...axiosOptions, headers })
}

export async function getJobUsers(user, axiosOptions, page, size, order, search, filter) {
  console.log("inside get job users", filter)
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.stsTokenManager.accessToken,
  };

  return axios.get(BASE_HATTRICK_URL + `marketplace/jobUser/all?page=${page}&size=${size}&order=${btoa(JSON.stringify(order))}&search=${btoa(search ? search : "")}&filter=${btoa(JSON.stringify(filter))}`,
    { ...axiosOptions, headers })
}

export async function getJob(user, id) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.stsTokenManager.accessToken,
  };

  return axios.get(BASE_HATTRICK_URL + `marketplace/job?id=${id}`, { headers })
}

export async function getBrand(user, id) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.stsTokenManager.accessToken,
  };

  return axios.get(BASE_HATTRICK_URL + `marketplace/brand?id=${id}`, { headers })
}

export async function getBrandByUserId(user, userId) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.stsTokenManager.accessToken,
  };

  return axios.get(BASE_HATTRICK_URL + `marketplace/brand?userId=${userId}`, { headers })
}

export async function getBrands(user, page, size, order) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.stsTokenManager.accessToken,
  };

  return axios.get(BASE_HATTRICK_URL + `marketplace/brand/all?page=${page}&size=${size}&order=${order}`, { headers })
}

export async function getUser(user, id) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.stsTokenManager.accessToken,
  };

  return axios.get(BASE_HATTRICK_URL + `marketplace/user?id=${id}`, { headers })
}

export async function getDeliverable(user, id) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.stsTokenManager.accessToken,
  };

  return axios.get(BASE_HATTRICK_URL + `marketplace/deliverable?id=${id}`, { headers })
}

export async function updateDeliverable(user, deliverableAssetId, jobId, athleteUserId, accepted, reason) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.stsTokenManager.accessToken,
  };

  return axios.put(BASE_HATTRICK_URL + `marketplace/deliverable`, { accepted, reason, jobId: parseInt(jobId), athleteUserId, deliverableAssetId: parseInt(deliverableAssetId) }, { headers })
}

export async function acceptApplication(user, jobUserId) {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.stsTokenManager.accessToken,
  };

  return axios.put(BASE_HATTRICK_URL + `marketplace/jobUser`, { jobUserId }, { headers })
}
