import { Outlet } from "react-router-dom";
import ContextProvider from "./contexts/ProviderProvider";
import { useEffect } from "react";
import Dashboard from "./pages/Dashboard";
import * as React from "react";
import "./App.css";
import { keepTheme, setLocalTheme } from "./utilities/themeUtilities";

import { getEmployeeRole } from "./API/employee";
import { getSchools } from "./API/school";
import { getRosterByAuthEmployeeSchool } from "./API/roster.js";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  onIdTokenChanged,
} from "firebase/auth";
import useNotification from "./hooks/useNotification.jsx";
import { getDocs, collection } from "firebase/firestore";

import { getSportList } from "./API/products";
import { app } from "./API/firebase.ts";
import { getFirestore } from "firebase/firestore";
const db = getFirestore(app);

const AppLayout = ({ user, setUser }) => {
  const [role, setRole] = React.useState("");
  const [theme, setTheme] = React.useState(localStorage.getItem("theme"));
  const [schools, setSchools] = React.useState([]);
  const [genderedSportList, setGenderedSports] = React.useState([]);
  const [userSchools, setUserSchools] = React.useState([]);
  const [permission, setPermission] = React.useState({});
  const [availableRoles, setAvailableRoles] = React.useState([]);
  const [actions, setActions] = React.useState({});
  const [views, setViews] = React.useState({});
  const [isNavOpen, setIsNavOpen] = React.useState(true);

  const auth = getAuth();
  React.useEffect(() => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => { })
      .catch((error) => console.error(`Error setting persistance`, error));
  }, [auth]);
  const notify = useNotification();

  const setThemeLocal = (theme) => {
    setLocalTheme(theme);
    setTheme(theme);
  };

  React.useEffect(() => {
    keepTheme();
  }, []);

  const fetchUserDerivedData = React.useCallback(async (user) => {

    getEmployeeRole(user)
      .then((response) => {
        setRole(response.data.role);
      })
      .catch((e) => console.log("Failed to get role for", user));

    if (!user || !user?.accessToken) return;
    getSchools(user)
      .then((response) => {
        setSchools(response.data.schools);
      })
      .catch((e) => {
        notify("Failed to get complete school list");
      });

    if (!user || !user?.accessToken) return;
    getSportList(user)
      .then((response) => {
        setGenderedSports(response.data.obj);
      })
      .catch((e) => {

        notify(e?.response?.data?.err);
      });

    if (user) {
      getRosterByAuthEmployeeSchool(user)
        .then((response) => {
          const unis = response.data.school.map((s) => s.university);
          setUserSchools(unis);
        })
        .catch((error) => {
          console.error(error);
          notify("Failed to get get this users school list");
          setUserSchools([]);
        });
    }
  }, [notify])

  React.useEffect(() => {
    // Listen to changes in authentication state

    const unsubscribe = auth.onAuthStateChanged(async (updatedUser) => {
      const fetchData = async (updatedUser) => {
        if (updatedUser) {

          await fetchUserDerivedData(updatedUser)
        }
      };

      // call the function
      await fetchData(updatedUser);
      setUser(JSON.parse(JSON.stringify(updatedUser)));
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [auth, fetchUserDerivedData]);

  React.useEffect(() => {
    if (!user) {
      return;
    }
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      console.log("Id token changed");
      console.log(`ID USER`, user);

      if (user) {
        await fetchUserDerivedData(user)
        setUser(user ? { ...user } : null);
        // User is signed in, check the token expiration
        // You can also check the expiration time in user.getIdTokenResult().expirationTime
        const now = new Date().getTime();
        const expiresIn = user.stsTokenManager.expirationTime - now;
        //console.log("Token expires in ", expiresIn);

        // Set a timeout to refresh the token before it expires
        const refreshTimeout = setInterval(() => {
          // Call a function to refresh the token
          user.getIdToken(true).then((response) => {
            setUser(auth.currentUser);
          });
        }, Math.max(0, expiresIn - 300000)); // Refresh 5 minutes before expiration

        // Cleanup function to clear the timeout when the component unmounts
        return () => clearTimeout(refreshTimeout);
      } else {
        setUser(null);
      }
    });

    // Cleanup function to unsubscribe from the observer when the component unmounts
    return () => unsubscribe();
  }, [auth, fetchUserDerivedData]);

  React.useEffect(() => {
    const fetchPermissions = async () => {
      try {
        // Query Firestore for permissions based on user's role
        const querySnapShot = await getDocs(
          collection(db, "Admin Permissions Matrix")
        );
        let perm = null;
        let roles = [];
        let action = null;
        let view = null;
        querySnapShot.forEach((doc) => {
          if (doc.id.toString() === role) {
            perm = doc.data();
          }
          if (doc.id.toString() === "Actions") action = doc.data();
          else if (doc.id.toString() === "Views") view = doc.data();
          else roles.push(doc.id.toString());
        });
        if (perm) {
          setPermission({ ...perm });
        } else {
          console.log("Unable to get permissions");
        }
        if (roles.length > 0) {
          setAvailableRoles(roles);
        } else {
          console.log("Unable to get all roles");
        }
        if (action) {
          setActions(action);
        } else {
          console.log("Unable to get all actions");
        }
        if (view) {
          setViews(view);
        } else {
          console.log("Unable to get all views");
        }
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };
    if (role && db) {
      fetchPermissions();
    }
  }, [role]);


  return (
    <ContextProvider
      user={user ? { ...user } : user}
      role={role}
      setRole={setRole}
      permission={permission}
      availableRoles={availableRoles}
      actions={actions}
      views={views}
      schools={schools}
      genderedSportList={genderedSportList}
      setSchools={setSchools}
      theme={theme}
      setTheme={setThemeLocal}
      userSchools={userSchools}
      isNavOpen={isNavOpen}
      setIsNavOpen={setIsNavOpen}
    >
      <Dashboard>
        <Outlet />
      </Dashboard>
    </ContextProvider>
  );
};

export default AppLayout;
