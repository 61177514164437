import axios from "axios";

const BALLPARK_BACKEND_BASE_URL = process.env.REACT_APP_BALLPARK_API_URL;

export const getEmployees = (user) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.stsTokenManager.accessToken,
    },
  };

  return axios.get(BALLPARK_BACKEND_BASE_URL + "employeeRoles", config);
};

export const getInterns = (user) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.stsTokenManager.accessToken,
    },
  };

  return axios.get(BALLPARK_BACKEND_BASE_URL + "socialInterns", config);
};

export const updateEmployees = (payload, user) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.stsTokenManager.accessToken,
    },
  };

  return axios.post(
    BALLPARK_BACKEND_BASE_URL + "employeeRoles",
    payload,
    config
  );
};
export const createEmployee = async (user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.stsTokenManager.accessToken,
    "Access-Control-Request-Headers": "authorization",
    "Access-Control-Request-Method": "POST",
  };

  const response = await axios.post(
    BALLPARK_BACKEND_BASE_URL + "employee",
    {},
    { headers }
  );
  console.log("tried to create new employee:", response);
};

export const configureEmployee = (config, user) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.stsTokenManager.accessToken,
    // "Access-Control-Request-Headers": "authorization",
    // "Access-Control-Request-Method": "POST",
  };

  return axios.post(BALLPARK_BACKEND_BASE_URL + "configure/employee", config, {
    headers,
  });
};

export const changeEmployeeRole = (user, email, newRole, fails, callback) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user.stsTokenManager.accessToken,
    "Access-Control-Request-Headers": "authorization",
    "Access-Control-Request-Method": "POST",
  };

  return axios.put(
    BALLPARK_BACKEND_BASE_URL + "employee",
    { email, role: newRole },
    { headers }
  );
};

export const getEmployeeRole = (user) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.stsTokenManager.accessToken,
    },
  };

  return axios.get(BALLPARK_BACKEND_BASE_URL + "employee", config);
};

export const getBizDevs = (user) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.stsTokenManager.accessToken,
    },
  };
  return axios.get(BALLPARK_BACKEND_BASE_URL + "bizDevs", config);
};
