import axios from "axios";
import { useEffect, useState, useCallback, useMemo, memo } from "react";
import { useUserContext } from "../../../contexts/ProviderProvider";
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, Scatter, ScatterChart } from "recharts"
import { Accordion, AccordionSummary, Typography } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const CustomScatterShape = (props) => {
    const { cx, cy, payload } = props;
    return (
        <circle
            cx={cx}
            cy={cy}
            r={5}
            fill={getColor(payload.success)}
            stroke="none"
        />
    );
};

const getColor = (value) => {
    if (value) {
        return '#00FF00'; // Green
    } else {
        return '#FF0000' // red
    }
};

const GrandSlamRunningAnalytics = () => {
    console.log("rendering");
    const [data, setData] = useState([]);
    const user = useUserContext();

    useEffect(() => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.stsTokenManager.accessToken,
        };

        axios.get(`${process.env.REACT_APP_HATTRICK_URL}hattrick/analytics`, { headers })
            .then(response => {
                setData(response.data.obj);
            })
            .catch(e => {
                console.log(e);
            });
    }, [user.stsTokenManager.accessToken]);

    const formatXAxis = useCallback((tickItem) => {
        // Your date formatting logic here
    }, []);

    const CustomScatterShapeMemo = useMemo(() => <CustomScatterShape />, []);

    const dataMemo = useMemo(() => data, [data]);

    return (
        <>
            <ScatterChart width={1500} height={1000} title="Start Time vs Duration">
                <CartesianGrid />
                <XAxis
                    type="number"
                    dataKey="start"
                    name="Start Time"
                    tickFormatter={formatXAxis}
                    domain={['auto', 'auto']}
                />
                <YAxis
                    type="number"
                    dataKey="duration"
                    name="Duration (Seconds)"
                    domain={['auto', 'auto']}
                />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Legend />
                <Scatter
                    name="Product Ran"
                    data={dataMemo}
                    fill="#8884d8"
                    shape={CustomScatterShapeMemo}
                />
            </ScatterChart>
            <ScatterChart width={1500} height={1000} title="Start Time vs End Time">
                <CartesianGrid />
                <XAxis
                    type="number"
                    dataKey="start"
                    name="Start Time"
                    tickFormatter={formatXAxis}
                    domain={['auto', 'auto']}
                />
                <YAxis
                    type="number"
                    dataKey="end"
                    name="End Time"
                    tickFormatter={formatXAxis}
                    domain={['auto', 'auto']}
                />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Legend />
                <Scatter
                    name="Product Ran"
                    data={dataMemo}
                    fill="#8884d8"
                    shape={CustomScatterShapeMemo}
                />
            </ScatterChart>
        </>
    );
};

export default memo(GrandSlamRunningAnalytics);
