import * as React from "react";
import {
  Drawer,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
  IconButton,
  Box,
  CircularProgress,
} from "@mui/material";
import Can from "./Can.jsx";
import LogoutIcon from "@mui/icons-material/Logout";
import Logo from "../../images/Influxer-Logo.jpeg";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Toggle from "../../utilities/themeToggle.jsx";
import { signOutWithGoogle } from "../../API/firebase.ts";
import {
  useRoleContext,
  useUserContext,
  usePermissionContext,
} from "../../contexts/ProviderProvider.jsx";

const PageSelector = (props) => {
  const { text, icon, onClick, path, display, role } = props;
  const { views } = usePermissionContext();

  const currentlyViewing =
    text === "Welcome"
      ? window.location.href.endsWith("ballpark")
      : window.location.href.includes(path.split("/")[0]);

  if (display === false) {
    return <></>;
  }

  return (
    <Can action={views.VIEW} items={[text]} wha={"Page Selector:" + text}>
      <ListItem
        key={text}
        sx={{ paddingTop: "16px", paddingBottom: "0px", width: "100%" }}
      >
        <ListItemButton
          onClick={onClick}
          sx={{
            border: "1px solid",
            borderColor: currentlyViewing ? "secondary.main" : "text.primary",
            width: "100%",
          }}
        >
          <ListItemIcon
            sx={{ color: currentlyViewing ? "primary.main" : "text.primary" }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            sx={{
              width: "100%",
              color: currentlyViewing ? "secondary.main" : "text.primary",
            }}
            primary={text}
          />
        </ListItemButton>
      </ListItem>
    </Can>
  );
};

const NavDrawer = ({ open, close, drawerWidth, components, drawerRef }) => {
  const navigate = useNavigate();
  const user = useUserContext();
  const role = useRoleContext().role;

  return (
    <Drawer
      style={{}}
      variant="persistent"
      anchor={"left"}
      open={open}
      ref={drawerRef}
      PaperProps={{
        sx: { ...drawerWidth, overflowX: "hidden", zIndex: "9999" },
      }}
      sx={{ ...drawerWidth, zIndex: "9999" }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box style={{ display: "flex", flexDirection: "column" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="start"
            sx={{
              position: "sticky",
              zndex: "1000",
              top: "0",
              padding: "13px",
              backgroundColor: "primary.main",
              minHeight: "64px",
              height: "64px",
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
              ...drawerWidth,
            }}
          >
            <Grid
              container
              item
              style={{
                height: "40px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "nowrap",
              }}
            >
              <button
                style={{
                  padding: "unset",
                  maxHeight: "auto",
                  maxWidth: "auto",
                  height: "40px",
                  border: "unset",
                  background: "none",
                }}
                onClick={() => navigate("/ballpark")}
              >
                <img
                  src="/icon_ballpark-svg.png"
                  style={{
                    maxHeight: "25px",
                    width: "auto",
                    paddingLeft: "5px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignContent: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </button>
              <IconButton
                onClick={close}
                style={{ width: "40px", height: "40px" }}
              >
                <ChevronLeftIcon
                  sx={{ color: "text.primary", fontSize: "40px" }}
                />
              </IconButton>
            </Grid>
          </Grid>
          {/* <Divider style={{ backgroundColor: "white" }} /> */}
          <div style={{ ...drawerWidth }}>
            {Object.keys(components).length > 0 && user && role.length > 0 ? (
              Object.keys(components).map((componentKey) => (
                <PageSelector
                  key={components[componentKey].text}
                  {...components[componentKey]}
                  role={role}
                />
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "400px",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </div>
        </Box>
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "background.default",
            ...drawerWidth,
          }}
        >
          <ListItem style={{ display: "flex", justifyContent: "center" }}>
            <ListItemButton
              style={{
                margin: "auto",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => {
                signOutWithGoogle().then(() => {
                  navigate("/login");
                });
              }}
            >
              <LogoutIcon style={{ paddingRight: "10px" }} />
              Log Out
            </ListItemButton>
          </ListItem>

          <ListItem style={{ display: "flex", justifyContent: "center" }}>
            <Toggle style={{ margin: "auto" }} />
          </ListItem>
        </Box>
      </Box>
    </Drawer>
  );
};

export default NavDrawer;
